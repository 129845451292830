import { Txt, VStack } from "@pomebile/primitives"
import { useEffect, useState } from "react"
import {
  CreditApplicationOutcome,
  SubmitApplicationResponse,
  SubmitCreditResponse,
} from "../api/webRoutes"
import { useLogging } from "../utils/logging"
import { ScreenFrame } from "../components/ScreenFrame"

export type SubmitApplicationScreenProps = {
  api: {
    submitApplication: () => Promise<SubmitCreditResponse>
  }

  onDone: (outcome: CreditApplicationOutcome | { tag: "reachedTimeLimit" }) => void
}

// TODO revisit this screen's API implementation
export function SubmittingApplicationScreen({
  onDone,
  api: { submitApplication },
}: SubmitApplicationScreenProps) {
  const { logError } = useLogging()

  useEffect(() => {
    submitApplication()
      .then((resp) => {
        switch (resp.tag) {
          case "frozen_credit":
            onDone({ tag: "frozenCredit" })
            break

          case "secured":
            onDone({
              tag: "approvedSecured",
              creditAppIdent: resp.creditAppIdent,
              updatedPromos: [],
            })
            break

          case "unsecured":
            onDone({
              tag: "approvedUnsecured",
              creditAppIdent: resp.creditAppIdent,
              updatedPromos: [],
              approvedLimit: resp.approvedLimit,
            })
            break

          default:
            onDone({ tag: "errorSubmittingApplication" })
            break
        }
      })
      .catch((err) => {
        logError(err)
        onDone({
          tag: "errorSubmittingApplication",
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScreenFrame>
      <VStack alignItems="center" justifyContent="center" height="full">
        <img src="images/pomelo_spinner_gray.gif" alt="Loading Spinner" width={60} height={60} />
        <Txt variant="headline2" textAlign="center">
          Submitting info
        </Txt>
        <Txt variant="body2" textAlign="center">
          This may take up to a minute.
        </Txt>
      </VStack>
    </ScreenFrame>
  )
}
