import {
  IdVerificationRequirementEffectivDecisionEnum,
  UserDto,
  VeriffDetailsDtoVeriffStateEnum,
} from "@pomebile/pomelo-service-api"
import {
  AddSignUpResponse,
  AuthExistingUser,
  AuthTempToken,
  CreditApplicationOutcome,
  GCashExchangeRate,
  PomeloCardPaymentMethod,
  Promo,
  SsnRequirementStatus,
  TerminalKycOutcome,
  USAddress,
  UserPersonalInfo,
  VeriffRequirementStatus,
} from "./api/webRoutes"
import { USAddressSchema, USAddressShortCodeSchema } from "./screens/HomeAddress"
import { CardColors } from "@pomebile/primitives/tokens"
import { Card } from "./screens/UnsecuredOffer/UnsecuredOffer"
import {
  taggedUnion,
  matchDeferred,
  Variants,
  matchDeferredTuple,
} from "@pomebile/shared/tagged-union"
import { AuthData, Tokens } from "./api/authContext"
import { mapUserPromos } from "./utils/promo"
import { ProductTypes } from "./screens/Complete"

export type ScreenKind = keyof Screens

type ApplicationScreen = {
  application: OnboardingApplication
  auth: AuthData
}

type AcceptPlanScreen<K extends keyof ProcessingOfferState = "userIdent"> = {
  processingOffer: Omit<ProcessingOfferState, K> & {
    [key in K]: Exclude<ProcessingOfferState[key], undefined>
  }
  auth: AuthData
}

interface Screens {
  ReturningUserLoader: { persistedTokens: Tokens }
  Phone: void
  OTP: { phoneNumber: string }
  UserInfo: { smsToken: string; phoneNumber: string }
  HomeAddress: ApplicationScreen
  Income: ApplicationScreen
  VerifyIdentity: ApplicationScreen
  FullSsn: ApplicationScreen
  SubmittingApplicationScreen: ApplicationScreen // Submit app progression screen
  CardSelector: AcceptPlanScreen
  Veriff: ApplicationScreen
  ManualReview: void
  TimeLimitError: { email: string }
  GeneralError: void
  PostDownload: { auth: AuthData; recipientFirstName: string }
  Rejected: void
  SecuredOffer: AcceptPlanScreen<"creditApplicationIdent">
  UnsecuredOffer: AcceptPlanScreen<"creditApplicationIdent" | "approvedLimit">
  Complete: { auth: AuthData; promos: Promo[]; productType: ProductTypes }
  FrozenCredit: ApplicationScreen
}

export const AppScreen = taggedUnion<Screens>()
export type AppScreen = Variants<Screens>

export type OnboardingApplication = {
  userIdent: string
  personalInfo: UserPersonalInfo
  address: USAddress | undefined
  statedIncome: number | undefined // should it be a wrapper type to represent USD?
  ssnStatus: SsnRequirementStatus
  veriffRequirementStatus: VeriffRequirementStatus
  promos: Promo[]
}

export type ProcessingOfferState = {
  userIdent: string
  personalInfo: UserPersonalInfo
  address: USAddress
  applicationOutcome: CreditApplicationOutcome
  offerAccepted: boolean | undefined // undefined expresses that the user haven't accepted/declined the offer yet
  cardOptions: Card[]
  selectedCard: CardColors | undefined // undefined expresses that the user did not get to choose a card color, most likely as a result of the cardOptions state being empty
  creditApplicationIdent: string | undefined // undefined expresses that the user haven't submitted an application yet
  pomeloCardPaymentMethod: PomeloCardPaymentMethod | undefined
  productGroupIdent: string | undefined
  promos: Promo[]
  gcashExchangeRate: GCashExchangeRate | undefined
  paymentTransactionIdent: string | undefined
  approvedLimit: number | undefined
}

interface AppStates {
  FetchReturningUser: { persistedTokens: Tokens }
  Initial: { phoneNumber: string | undefined }
  SigningUp: { phoneNumber: string; smsToken: string }

  // TODO extract auth state to a `useRef` instead
  Applying: {
    application: OnboardingApplication
    auth: AuthData
  }

  AcceptUnsecuredPlan: AcceptPlanScreen
  AcceptSecuredPlan: AcceptPlanScreen

  // means that we are done, show "Download App" screen
  Finished: {
    auth: AuthData
    recipientFirstName: string
  }

  TimeoutError: { email: string }

  GeneralError: void

  Complete: {
    auth: AuthData
    promos: Promo[]
    productType: ProductTypes
  }

  ManualReview: {
    auth: AuthData
  }
  Rejected: {
    auth: AuthData
  }

  FrozenCredit: {
    application: OnboardingApplication
    auth: AuthData
  }
}

interface AppEvents {
  PhoneNumberSubmitted: string

  AuthCompleted: {
    authResult: AuthTempToken | AuthExistingUser
  }

  UserFetchCompleted: {
    phoneNumber: string
    authResult: AuthExistingUser
  }

  SignedUp: {
    personalInfo: UserPersonalInfo
    signUpResult: AddSignUpResponse
  }

  ApplicationSubmitted: CreditApplicationOutcome | { tag: "reachedTimeLimit" }

  AddedAddress: USAddress

  ReportedIncome: number

  RequiredFullSsn: void

  RequiredVeriff: void

  CompletedKyc: TerminalKycOutcome

  RequiredSupport: void

  RequestTimedOut: void

  AcceptedUnsecuredOffer: {
    cards: Card[]
    productGroupIdent: string
    updatedPromos: Promo[]
  }

  AcceptedSecuredOffer: {
    productGroupIdent: string
    updatedPromos: Promo[]
  }

  SelectedCard: CardColors

  // All errors should have an errorType
  // errorType will be exposed in Sentry breadcrumbs (don't include PII)

  TimeLimitExceeded: { errorType: string; email: string }

  EncounteredGeneralError: { errorType: string }
}

export const AppState = taggedUnion<AppStates>()
export type AppState = Variants<AppStates>

export const AppEvent = taggedUnion<AppEvents>()
export type AppEvent = Variants<AppEvents>

const initOnboardingState = (serverUser: UserDto, phoneNumber: string): OnboardingApplication => {
  const {
    personalInfo: { email, firstName, lastName, address, statedIncome, phone },
    ident,
  } = serverUser
  // DO we need to double check that the address is legit?

  // TODO handle NRAU accidental signup, we need to identify non US returning users
  let parsedAddress: USAddress | undefined
  if (address) {
    const { lineOne = "", lineTwo, city = "", zip, region: state = "", country } = address

    if (country === "US") {
      const a: USAddress = {
        addressLineOne: lineOne,
        addressLineTwo: lineTwo,
        zip,
        state,
        city,
        country,
      }

      if (USAddressShortCodeSchema.isValidSync(a)) {
        parsedAddress = a
      }
    }
  }

  // SSN cases:
  //  Not started
  //  SSN good
  //  SSN bad - need full
  // const needFull = serverUser.signUp.signUpContextDto?.ssnRequirement?.fallbackFullNine
  // // ssn is good
  // const ssnIsGood =
  //   serverUser.signUp.signUpContextDto?.ssnRequirement?.signUpRequirementStatus === "COMPLETE"
  // const noLast4 =
  //   serverUser.signUp.signUpContextDto?.ssnRequirement?.signUpRequirementStatus === "NOT_STARTED"

  const res: OnboardingApplication = {
    userIdent: ident,
    address: parsedAddress,
    statedIncome,
    ssnStatus:
      serverUser.signUp.signUpContextDto?.ssnRequirement?.signUpRequirementStatus === "COMPLETE"
        ? "complete"
        : serverUser.signUp.signUpContextDto?.ssnRequirement?.fallbackFullNine
          ? "requiresFullSSN"
          : "notStarted",
    veriffRequirementStatus:
      serverUser.signUp.signUpContextDto?.idVerificationRequirement?.effectivDecision === "REVIEW"
        ? serverUser.signUp.signUpContextDto?.idVerificationRequirement?.veriffDetails
            ?.veriffState &&
          ["approved", "declined", "review"].includes(
            serverUser.signUp.signUpContextDto?.idVerificationRequirement?.veriffDetails
              ?.veriffState,
          )
          ? "complete"
          : "required"
        : "notRequired",

    personalInfo: {
      firstName,
      lastName,
      email,
      phoneNumber: phone?.phoneNumber ?? phoneNumber,
    },
    promos: serverUser.promos ? mapUserPromos(serverUser.promos) : [],
  }

  return res
}

/**
 * The Applying and FrozenCredit states are both points of application/re-application for users going through the onboarding flow.
 * We need to ensure that the application logic is shared between these two states, hence, this function.
 */
const handleApplicationSubmittedEvent = (
  prev: { application: OnboardingApplication; auth: AuthData },
  outcome: AppEvents["ApplicationSubmitted"],
): AppState => {
  const { userIdent, address, personalInfo, promos } = prev.application

  if (outcome.tag === "reachedTimeLimit") {
    return TimeoutError({ email: personalInfo.email })
  }

  if (outcome.tag === "errorSubmittingApplication") {
    return GeneralError()
  }

  if (outcome.tag === "frozenCredit") {
    return FrozenCredit({ ...prev })
  }

  const offer: ProcessingOfferState = {
    userIdent,
    personalInfo,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    address: address!, // TODO pass it though SubmittingScreen
    applicationOutcome: outcome,
    offerAccepted: undefined,
    selectedCard: undefined,
    cardOptions: [],
    creditApplicationIdent: undefined,
    pomeloCardPaymentMethod: undefined,
    productGroupIdent: undefined,
    gcashExchangeRate: undefined,
    promos:
      outcome.tag === "approvedSecured" || outcome.tag === "approvedUnsecured"
        ? outcome.updatedPromos
        : promos,
    paymentTransactionIdent: undefined,
    approvedLimit: undefined,
  }

  switch (outcome.tag) {
    case "approvedUnsecured":
      return AcceptUnsecuredPlan({
        auth: prev.auth,
        processingOffer: {
          ...offer,
          creditApplicationIdent: outcome.creditAppIdent,
          approvedLimit: outcome.approvedLimit,
        },
      })

    case "approvedSecured":
      return AcceptSecuredPlan({
        auth: prev.auth,
        processingOffer: {
          ...offer,
          creditApplicationIdent: outcome.creditAppIdent,
        },
      })

    case "veriffRequired":
      return Applying({
        auth: prev.auth,
        application: {
          ...prev.application,
          veriffRequirementStatus: "required",
        },
      })

    case "manualReview":
      return ManualReview({ auth: prev.auth })

    case "rejected":
      return Rejected({ auth: prev.auth })
  }
}

const handleAuthCompleted = (
  phoneNumber: string | undefined,
  { authResult }: { authResult: AuthTempToken | AuthExistingUser },
) => {
  if (!phoneNumber) {
    return Initial({ phoneNumber })
  }

  if (authResult.tag === "newUser") {
    return SigningUp({
      phoneNumber: phoneNumber,
      smsToken: authResult.smsTempToken,
    })
  }

  const { user, token, refreshToken } = authResult
  const { signUp, approvedCreditAppDto, personalInfo } = user
  const { signUpContextDto } = signUp
  const promos = user.promos ? mapUserPromos(user.promos) : []

  const auth: AuthData = {
    tokens: { token, refreshToken },
    email: personalInfo.email,
    userIdent: user.ident,
  }

  // if a returning user already accepted their plan
  if (signUpContextDto?.offerRequirement?.signUpRequirementStatus === "COMPLETE") {
    return Complete({
      auth,
      promos,
      productType:
        approvedCreditAppDto.productType === "CHARGE_CARD_UNSECURED" ? "unsecured" : "secured",
    })
  }

  // handle terminal states based on Effectiv evaluations
  const manualReviewEffectivDecisions: IdVerificationRequirementEffectivDecisionEnum[] = [
    "CANCEL",
    "REVIEW",
  ]
  const rejectedEffectivDecisions: IdVerificationRequirementEffectivDecisionEnum[] = ["DECLINE"]
  const terminalVeriffStates: VeriffDetailsDtoVeriffStateEnum[] = ["declined", "approved"]

  const effectivDecision = signUpContextDto?.idVerificationRequirement?.effectivDecision
  const veriffState = signUpContextDto?.idVerificationRequirement?.veriffDetails?.veriffState

  if (effectivDecision && manualReviewEffectivDecisions.includes(effectivDecision)) {
    if (veriffState && terminalVeriffStates.includes(veriffState)) {
      return ManualReview({ auth })
    }
  }

  if (effectivDecision && rejectedEffectivDecisions.includes(effectivDecision)) {
    return Rejected({ auth })
  }

  const userIncome = personalInfo.statedIncome
  const userAddress = personalInfo.address

  let parsedAddress: USAddress | undefined
  if (userAddress) {
    const { lineOne = "", lineTwo, city = "", zip, region: state = "", country } = userAddress
    if (country === "US") {
      const a: USAddress = {
        addressLineOne: lineOne,
        addressLineTwo: lineTwo,
        zip,
        state,
        city,
        country,
      }

      if (USAddressSchema.isValidSync(a)) {
        parsedAddress = a
      }
    }
  }

  const isCreditFrozen = signUpContextDto?.underwritingRequirement?.frozenCredit || false

  // When signUpContextDto.underwritingRequirement.signUpRequirementStatus === "COMPLETE", this generally means that we can already show users the offer screen UNLESS the have frozen credit.
  // We're checking parsedAddress and userIncome for type safety purposes.
  if (
    parsedAddress &&
    typeof userIncome === "number" &&
    signUpContextDto?.underwritingRequirement?.signUpRequirementStatus === "COMPLETE" &&
    !isCreditFrozen
  ) {
    const processingOffer: Omit<ProcessingOfferState, "applicationOutcome"> = {
      userIdent: user.ident,
      address: parsedAddress,
      personalInfo: {
        email: personalInfo.email,
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        phoneNumber: personalInfo.phone?.phoneNumber ?? phoneNumber,
      },
      promos,
      creditApplicationIdent: approvedCreditAppDto.ident,
      offerAccepted: undefined,
      selectedCard: undefined,
      cardOptions: [],
      pomeloCardPaymentMethod: undefined,
      productGroupIdent: undefined,
      gcashExchangeRate: undefined,
      paymentTransactionIdent: undefined,
      approvedLimit: undefined,
    }

    if (
      approvedCreditAppDto.productType === "CHARGE_CARD_UNSECURED" &&
      typeof approvedCreditAppDto.approvedLimit === "number"
    ) {
      const unsecuredOffer: ProcessingOfferState = {
        ...processingOffer,
        applicationOutcome: {
          tag: "approvedUnsecured",
          updatedPromos: user.promos ? mapUserPromos(user.promos) : [],
          creditAppIdent: approvedCreditAppDto.ident,
          approvedLimit: approvedCreditAppDto.approvedLimit,
        },
      }

      return AcceptUnsecuredPlan({ auth, processingOffer: unsecuredOffer })
    } else if (approvedCreditAppDto.productType === "CHARGE_CARD_SECURED") {
      const securedOffer: ProcessingOfferState = {
        ...processingOffer,
        applicationOutcome: {
          tag: "approvedSecured",
          updatedPromos: user.promos ? mapUserPromos(user.promos) : [],
          creditAppIdent: approvedCreditAppDto.ident,
        },
      }

      return AcceptSecuredPlan({ auth, processingOffer: securedOffer })
    }
  }

  const application = initOnboardingState(user, phoneNumber)

  if (isCreditFrozen) {
    return FrozenCredit({ application, auth })
  }

  return Applying({ application, auth })
}

export const calculateRPCScreen: (state: AppState) => AppScreen = matchDeferred(AppState, {
  FetchReturningUser: ({ persistedTokens }) => AppScreen.ReturningUserLoader({ persistedTokens }),
  Initial: ({ phoneNumber }) => {
    if (!phoneNumber) {
      return AppScreen.Phone()
    }

    return AppScreen.OTP({ phoneNumber })
  },
  SigningUp: ({ smsToken, phoneNumber }) => AppScreen.UserInfo({ smsToken, phoneNumber }),

  Applying: ({ application, auth }) => {
    const authScreenParams: ApplicationScreen = {
      application,
      auth,
    }

    if (!application.address) {
      return AppScreen.HomeAddress(authScreenParams)
    }

    if (application.ssnStatus === "notStarted") {
      return AppScreen.VerifyIdentity(authScreenParams)
    }

    if (application.ssnStatus === "requiresFullSSN") {
      return AppScreen.FullSsn(authScreenParams)
    }

    if (application.veriffRequirementStatus === "required") {
      return AppScreen.Veriff(authScreenParams)
    }

    // statedIncome will be null for returning users who haven't reached the income step
    if (!application.statedIncome && typeof application.statedIncome !== "number") {
      return AppScreen.Income(authScreenParams)
    }

    return AppScreen.SubmittingApplicationScreen({ application, auth })
  },

  AcceptUnsecuredPlan: ({ processingOffer, auth }) => {
    if (
      processingOffer.offerAccepted === undefined &&
      processingOffer.creditApplicationIdent &&
      typeof processingOffer.approvedLimit === "number"
    ) {
      return AppScreen.UnsecuredOffer({
        auth,
        processingOffer: {
          ...processingOffer,
          creditApplicationIdent: processingOffer.creditApplicationIdent,
          approvedLimit: processingOffer.approvedLimit,
        },
      })
    }

    if (
      processingOffer.cardOptions.length &&
      !processingOffer.selectedCard &&
      processingOffer.userIdent
    ) {
      return AppScreen.CardSelector({
        auth,
        processingOffer,
      })
    }

    return AppScreen.GeneralError()
  },
  AcceptSecuredPlan: ({ processingOffer, auth }) => {
    if (processingOffer.offerAccepted === undefined && processingOffer.creditApplicationIdent) {
      return AppScreen.SecuredOffer({
        auth,
        processingOffer: {
          ...processingOffer,
          creditApplicationIdent: processingOffer.creditApplicationIdent,
        },
      })
    }

    if (processingOffer.offerAccepted) {
      return AppScreen.Complete({ auth, promos: processingOffer.promos, productType: "secured" })
    }

    return AppScreen.GeneralError()
  },

  ManualReview: () => {
    return AppScreen.ManualReview()
  },

  // TODO go to the right screens here
  Finished: ({ auth, recipientFirstName }) => AppScreen.PostDownload({ auth, recipientFirstName }),
  TimeoutError: ({ email }) => AppScreen.TimeLimitError({ email }),
  GeneralError: () => AppScreen.GeneralError(),
  Rejected: () => AppScreen.Rejected(),
  Complete: ({ auth, promos, productType }) => AppScreen.Complete({ auth, promos, productType }),
  FrozenCredit: ({ application, auth }) => AppScreen.FrozenCredit({ auth, application }),
})

const {
  Initial,
  Applying,
  SigningUp,
  AcceptUnsecuredPlan,
  AcceptSecuredPlan,
  TimeoutError,
  GeneralError,
  ManualReview,
  Rejected,
  Complete,
  FrozenCredit,
} = AppState

export const updateRPCAppState = matchDeferredTuple(AppState, AppEvent, {
  FetchReturningUser: {
    UserFetchCompleted: (_, { phoneNumber, authResult }) =>
      handleAuthCompleted(phoneNumber, { authResult }),
  },
  Initial: {
    PhoneNumberSubmitted: (prev, phoneNumber) => Initial({ ...prev, phoneNumber }),
    EncounteredGeneralError: () => {
      return GeneralError()
    },
    AuthCompleted: (prev, authResult) => handleAuthCompleted(prev.phoneNumber, authResult),
  },
  SigningUp: {
    SignedUp: (_, { personalInfo, signUpResult: { userIdent, token, refreshToken, promos } }) => {
      const application: OnboardingApplication = {
        address: undefined,
        statedIncome: undefined,
        ssnStatus: "notStarted",
        veriffRequirementStatus: "notRequired",
        userIdent: userIdent,
        personalInfo,
        promos,
      }

      const auth: AuthData = {
        tokens: { token, refreshToken },
        userIdent,
        email: personalInfo.email,
      }

      return Applying({ application, auth })
    },
  },

  Applying: {
    AddedAddress: (prev, address) =>
      Applying({ ...prev, application: { ...prev.application, address } }),

    RequiredFullSsn: (prev) =>
      Applying({ ...prev, application: { ...prev.application, ssnStatus: "requiresFullSSN" } }),

    RequiredVeriff: (prev) =>
      Applying({
        ...prev,
        application: {
          ...prev.application,
          ssnStatus: "complete",
          veriffRequirementStatus: "required",
        },
      }),

    CompletedKyc: (prev, outcome) => {
      switch (outcome) {
        case "approved":
          return Applying({
            ...prev,
            application: {
              ...prev.application,
              ssnStatus: "complete",
              veriffRequirementStatus: "complete",
            },
          })

        case "rejected":
          return Rejected({ auth: prev.auth })

        case "manualReview":
          return ManualReview({ auth: prev.auth })
      }
    },

    RequiredSupport: () => GeneralError(),

    RequestTimedOut: (prev) => TimeoutError({ email: prev.auth.email }),

    ReportedIncome: (prev, statedIncome) =>
      Applying({ ...prev, application: { ...prev.application, statedIncome } }),

    ApplicationSubmitted: handleApplicationSubmittedEvent,
    TimeLimitExceeded: (_, { email }) => {
      return TimeoutError({ email })
    },

    EncounteredGeneralError: () => GeneralError(),
    // TODO add an event from the offer screen that submits the application
    // this event will actually transition out of "Applying state"
  },

  AcceptUnsecuredPlan: {
    AcceptedUnsecuredOffer: (prev, { cards, productGroupIdent, updatedPromos }) => {
      return AcceptUnsecuredPlan({
        ...prev,
        processingOffer: {
          ...prev.processingOffer,
          offerAccepted: true,
          cardOptions: cards,
          productGroupIdent,
          promos: updatedPromos,
        },
      })
    },
    SelectedCard: (prev, _cardColor) => {
      return Complete({
        auth: prev.auth,
        promos: prev.processingOffer.promos,
        productType: "unsecured",
      })
    },
    TimeLimitExceeded: (_, { email }) => {
      return TimeoutError({ email })
    },
    EncounteredGeneralError: () => {
      return GeneralError()
    },
  },

  AcceptSecuredPlan: {
    AcceptedSecuredOffer: (prev, { updatedPromos }) => {
      return Complete({ auth: prev.auth, promos: updatedPromos, productType: "secured" })
    },
    TimeLimitExceeded: (_, { email }) => {
      return TimeoutError({ email })
    },

    EncounteredGeneralError: () => {
      return GeneralError()
    },
  },

  FrozenCredit: {
    ApplicationSubmitted: handleApplicationSubmittedEvent,

    TimeLimitExceeded: (_, { email }) => {
      return TimeoutError({ email })
    },

    EncounteredGeneralError: () => GeneralError(),
  },

  default: (prev) => prev,
})
